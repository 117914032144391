exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-aktualnosci-js": () => import("./../../../src/templates/aktualnosci.js" /* webpackChunkName: "component---src-templates-aktualnosci-js" */),
  "component---src-templates-aktualnosci-single-js": () => import("./../../../src/templates/aktualnosci-single.js" /* webpackChunkName: "component---src-templates-aktualnosci-single-js" */),
  "component---src-templates-custom-page-js": () => import("./../../../src/templates/custom-page.js" /* webpackChunkName: "component---src-templates-custom-page-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-kola-naukowe-js": () => import("./../../../src/templates/kola-naukowe.js" /* webpackChunkName: "component---src-templates-kola-naukowe-js" */),
  "component---src-templates-kola-naukowe-single-js": () => import("./../../../src/templates/kola-naukowe-single.js" /* webpackChunkName: "component---src-templates-kola-naukowe-single-js" */),
  "component---src-templates-konferencje-hutnicze-js": () => import("./../../../src/templates/konferencje-hutnicze.js" /* webpackChunkName: "component---src-templates-konferencje-hutnicze-js" */),
  "component---src-templates-konferencje-single-js": () => import("./../../../src/templates/konferencje-single.js" /* webpackChunkName: "component---src-templates-konferencje-single-js" */),
  "component---src-templates-konferencje-studenckie-js": () => import("./../../../src/templates/konferencje-studenckie.js" /* webpackChunkName: "component---src-templates-konferencje-studenckie-js" */),
  "component---src-templates-kontakt-js": () => import("./../../../src/templates/kontakt.js" /* webpackChunkName: "component---src-templates-kontakt-js" */),
  "component---src-templates-publish-js": () => import("./../../../src/templates/publish.js" /* webpackChunkName: "component---src-templates-publish-js" */)
}

